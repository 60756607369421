import React, { useEffect, useRef, useState } from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PageContext } from "../store/context/page-context";

import "../assets/scss/fonts/cnFonts.scss";
import "../assets/scss/fonts/jpFonts.scss";
import "../assets/scss/fonts/localization.scss";

import "../assets/scss/terms-of-use/index.scss";
import "../components/instruction/index.scss";
import Offer from "../components/instruction/Offer";
import InfoSection from "../components/instruction/InfoSection";
import { graphql } from "gatsby";

const PgI = () => {
  const { t } = useTranslation();
  const [currentLocale, setCurrentLocale] = useState(null);
  const guideRef = useRef(null);

  const transitionToRef = (refParameter) => {
    !!refParameter &&
      !!refParameter.current &&
      refParameter.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.hash === "#guide") {
        transitionToRef(guideRef);
      }
    }
  }, []);
  return (
    <Layout bgHeader="#fff" setLocale={setCurrentLocale}>
      <PageContext.Provider
        value={{
          translate: t,
        }}
      >
        <Seo
          title="Here you can find a short user’s guide for our product | AMZScout"
          description="Here you can find a short user’s guide for our product | AMZScout"
          page={t("code") !== "en" ? `${t("code")}/instruction` : "instruction"}
          manifest="browserconfig.xml"
          ogImg={OgImg}
        />
        <Offer />
        <InfoSection currentLocale={currentLocale} guideRef={guideRef} />
      </PageContext.Provider>
    </Layout>
  );
};

export default PgI;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "instruction" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
